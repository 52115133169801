import './App.css';
import { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ScrollTo from './components/glopal/ScrollTo';
import NavBar from './components/NavBar';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import OurServices from './pages/OurServices';
import OurBlogs from './pages/OurBlogs';
import OueWork from './pages/OueWork';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Project from './pages/Project';
import Blog from './pages/Blog';

const languages = [
  {
    code: 'ar',
    name: 'Ar',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'En',
    country_coode: 'gb',
    dir: 'ltr'
  }
]

function App() {

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  const { t } = useTranslation();
  useEffect(() => {

    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');

  }, [currentLanguage, t,]);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <div className="App">
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <ScrollTo />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blogs" element={<OurBlogs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/projects" element={<OueWork />} />
        <Route path="/project/:id" element={<Project />} />
        <Route path="/contacts-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
