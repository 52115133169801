import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

const IntroductorySection = ({ dir, title, desc, Bt, width='48%', children }) => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: dir ? dir : 'row', flexWrap: { md: 'wrap-reverse', xs: 'wrap' }, gap:2, alignItems:'center', justifyContent:'space-between' }} >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: { md: width, xs: '100%' } }} >
                    <Typography color={'secondary.main'} variant={isMobile ? 'h6' :'h5'} >{title}</Typography> 
                        <Typography  color={'secondary.supMain'} sx={{ lineHeight: 1.7, fontSize: { md: '1.1rem', xs: '0.8rem' } }}>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: desc }}></div>
                        </Typography>
                    { Bt && <Box> {Bt} </Box> }
                </Box>
                {children}
            </Box>
        </>
    )
}

export default IntroductorySection