import React from 'react'
import WrapperSection from '../glopal/WrapperSection'
import GlobalList from '../glopal/GlobalList'
import CardValues from '../CoreValues/CardValues'
import { Box, Grid, Typography } from '@mui/material'
import { contacts } from '../../constants'
import i18next from 'i18next'
import Form from '../Form/Form'
import XIcon from '@mui/icons-material/X';
const ContactSocial = () => {
  let lng = i18next.language
  return (
    <>
      <WrapperSection>
        <GlobalList>
          <Grid item md={6} sm={6} xs={12} sx={{ my: { md: 0, xs: 1 } }} >
            <Box sx={{ p: 2, background: '#F7F7F7', borderRadius: '16px', mx: { md: 2, xs: 0 }, height: '100%', }} >
              <GlobalList>
                <Grid item xs={12}>
                  <CardValues
                    width='75%'
                    bgBox='#fff'
                    bg='#FFF'
                    title={'Twitter'}
                    m={'auto'}
                    icon={<XIcon />}>
                    <a href="https://x.com/alqahtani_est?s=11&t=hSO3A6-wFJsi2gF_kmg3jg"
                      style={{ textDecoration: 'none' }}
                      target="_blank" rel="noopener noreferrer">
                      <Typography sx={{
                        color: '#1E6FA1',
                        lineHeight: '1.8',
                      }}> https://x.com/alqahtani_est?s=11&t=hSO3A6-wFJsi2gF_kmg3jg</Typography>
                    </a>
                  </CardValues>
                </Grid>
                {
                  contacts?.map((contact, i) =>
                    <Grid item xs={12} key={i} >
                      <CardValues
                        width='75%'
                        bgBox='#fff'
                        bg='#FFF'
                        valueImg={contact.imageUrl}
                        title={lng === 'en' ? contact?.name : contact?.nameAr}
                        description={lng === 'en' ? contact?.supName : contact?.supNameAr}
                        m={'auto'}
                      />
                    </Grid>
                  )
                }
              </GlobalList>
            </Box>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ my: { md: 0, xs: 1 } }} >
            <Box sx={{ p: 2, background: '#F7F7F7', borderRadius: '16px', mx: { md: 2, xs: 0 }, height: '100%', }}>
              <Form />
            </Box>
          </Grid>
        </GlobalList>
      </WrapperSection>
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.81100295322!2d30.927131874540493!3d30.469790874712128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14587f312c6655ef%3A0x139b504b1245cf25!2sRevamp%20Brands!5e0!3m2!1sen!2seg!4v1709737789018!5m2!1sen!2seg" width="100%" height="600" title={'test'} style={{ border: 0, marginTop: '16px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
    </>
  )
}

export default ContactSocial