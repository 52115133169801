import React from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import Services from '../components/Services'

const OurServices = () => {
    const { t } = useTranslation()
    return (
        <>
            <Header url={t("linksPages.OurServices")}/>
            <Services />
        </>
    )
}

export default OurServices