import React, { useEffect, useState } from 'react'
import WrapperSection from '../glopal/WrapperSection'
import { Grid } from '@mui/material'
import GlobalList from '../glopal/GlobalList'
// import { services } from '../../constants'
import GlobalCard from '../glopal/GlobalCard'
import Btn from '../glopal/Btn'
import { useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { useGetHomeDataQuery } from '../../state/apiHome'
import { useTranslation } from 'react-i18next'

const Services = () => {
    const location = useLocation();
    let path = location.pathname.split('/')[1]

    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);
const {t} = useTranslation()
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.services)
        }
    }, [data, tableData, isBrandsLoading])
    return (
        <>
            <WrapperSection title={data?.data?.serviceTitle}>
                <GlobalList>
                    {
                        tableData?.map((arr, i) =>
                            <Grid item  md={4} sm={6} xs={12} key={i} >
                                <GlobalCard 
                                img={arr.image}
                                title={arr.title}
                                Align={'center'}
                                />
                            </Grid>
                        )
                    }
                </GlobalList>
                {
                    path !== 'services' && <Btn path={'services'} text={t("btn")} />
                }
                
            </WrapperSection>
        </>
    )
}

export default Services