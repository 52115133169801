
import { api } from "./apiHome";

export const ApiAuth = api.injectEndpoints({
    reducerPath: "apiHome",
    tagTypes: ['vehicles'],
    endpoints: (build) => ({
        getProjectData: build.query({
            query: (lng) => `/project?local=${lng}`,
            providesTags: ["project"],
        }),
        getProjectIdData: build.query({
            query: ({id, lng}) => `/project/${id}?local=${lng}`,
            providesTags: ["project"],
        })
    }),
});


export const {
    useGetProjectDataQuery,
    useGetProjectIdDataQuery,
} = ApiAuth;