import { Box, Container, Grid, Typography, useMediaQuery  } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../util/theme'
import Logo from './logo'
import Contact from './Contact'
import CustomerService from './CustomerService'
import { useTheme } from '@emotion/react'
import { useGetBasicDataQuery } from '../../state/apiHome'
import i18next from 'i18next'

import { Helmet } from "react-helmet";
const Footer = () => {

    let lng = i18next.language
    const { data, isBrandsLoading } = useGetBasicDataQuery(lng);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data)
        }
    }, [data, tableData, isBrandsLoading])

    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
        <Helmet>
                <link rel="icon" href={tableData?.logo} />
                <link rel="apple-touch-icon" href={tableData?.fav_icon} />
                <title>{tableData?.name}</title>
            </Helmet>
        <footer>
                <Box sx={{ backgroundColor: theme.palette.secondary.main, p:2 }} >
                        <Container maxWidth={'lg'}  >
                            <Grid container >
                                <Grid item md={4} xs={12}>
                                <CustomerService phone={tableData?.tel} />
                                </Grid>
                                {
                                isMobile || 
                                <Grid item md={4} xs={12}> 
                                    <Contact />
                                </Grid>
                                }
                                <Grid item md={4} xs={12}>
                                <Logo name={tableData?.name} />
                                </Grid>
                            </Grid>
                        {
                            isMobile && 
                            <Typography color={'primary.text'} textAlign={'center'} p={2} >
                                Copyright © 2024 REVAMP. All rights reserved.
                            </Typography>
                            }
                        </Container>
                </Box>
        </footer>
        </>
    )
}

export default Footer