import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sliders from './Sliders'
import './style.css'
import { useGetHomeDataQuery } from '../../state/apiHome'
import i18next from 'i18next'

const SliderHome = () => {
  let lng = i18next.language
  const { data, isBrandsLoading } = useGetHomeDataQuery(lng);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (data && !isBrandsLoading) {
      setTableData(data?.data?.slider)
    }
  }, [data, tableData, isBrandsLoading])

  return (
    <>
      <Box position={'relative'} >
        <Sliders data={tableData}  />
      </Box>
    </>
  )
}

export default SliderHome