import React from 'react'
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const WrapperSection = ({ title, desc, color, secColor, children }) => {
  const themeM = useTheme();
  const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
  return (
    <>
      <Container maxWidth={'lg'} >
        <Box sx={{ pt: { md: 4, xs: 1 }, pb: 1, mt: { md: 4, xs: 1 }, mb: 1, display: 'flex', flexDirection: 'column', gap: { md: 1.7,xs:1 }, alignItems: 'center', position: 'relative', zIndex: 11, textAlign: isMobile && 'center' }} >
          {
            title &&
            <Typography color={color ? color : 'secondary.main'} variant={isMobile ? 'h6' : 'h5'} sx={{ my:1.2 }} >{title}</Typography>
          }
          {desc && desc?.split('.')?.map((part, index) => (
            <Typography key={index} color={secColor ? secColor : 'secondary.supMain'} sx={{ textAlign: 'center', lineHeight: 1.7, fontSize: { md: '1.1rem', xs: '0.8rem' } }}>{part}</Typography>
          ))}
          {children}
        </Box>
      </Container>
    </>
  )
}

export default WrapperSection