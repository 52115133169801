
import React from 'react'
import GlobalList from '../glopal/GlobalList'
// import { projects1 } from '../../constants'
import { Box, CardMedia, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const RelatedProjects = ({ data }) => {

    const navigate = useNavigate()
    return (
        <>
            <GlobalList>
                {
                    data?.slice(0, 3)?.map(project => <Grid item md={4} sm={6} xs={12} key={project?.id} >
                        <Box position={'relative'} className={'parent'} m={1} onClick={() => navigate(`/project/${project?.id}`)}>
                            <div className="overlay_project">
                                {project?.title}
                            </div>
                            <CardMedia
                                component={'img'}
                                src={project?.images?.[0]?.image}
                                sx={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '15rem',
                                    borderRadius: '16px',

                                }} />
                        </Box>
                    </Grid>
                    )
                }
            </GlobalList>
        </>
    )
}

export default RelatedProjects