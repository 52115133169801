import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import i18next from 'i18next'
const Details = ({data}) => { 
    let lng = i18next.language
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }} >
                <Box sx={{ display: 'flex', justifyContent: {md:'space-around'},gap: {md:0,xs:2}, alignItems: 'center', width:'100%' }} >
                    <Typography color={'secondary.main'} variant={isMobile ||'h6'} sx={{ width:{md:'50%',xs:'unset'} }}  >{lng === 'ar' ?'الأسم' : 'name'} </Typography>
                    <Typography color={'secondary.supMain'} sx={{ width: { md: '50%', xs: 'unset' } }} >{data?.title}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: {md:'space-around'},gap: {md:0,xs:2}, alignItems: 'center', width:'100%' }} >
                    <Typography color={'secondary.main'} variant={isMobile ||'h6'} sx={{ width:{md:'50%',xs:'unset'} }}  >{lng === 'ar' ?'الموقع' : 'location' }</Typography>
                    <Typography color={'secondary.supMain'} sx={{ width: { md: '50%', xs: 'unset' } }} >{data?.location} </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: {md:'space-around'},gap: {md:0,xs:2}, alignItems: 'center', width:'100%' }} >
                    <Typography color={'secondary.main'} variant={isMobile ||'h6'}  sx={{ width:{md:'50%',xs:'unset'} }} >{lng === 'ar' ?'الحالة' : 'status' }</Typography>
                    <Typography color={'secondary.supMain'} sx={{ width: { md: '50%', xs: 'unset' } }} >{data?.status}</Typography>
                </Box>
            </Box>
        </>
    )
}

export default Details