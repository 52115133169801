import React, { useEffect, useState } from 'react'
import WrapperSection from '../components/glopal/WrapperSection'
import Header from '../components/glopal/Header'
import RelatedProjects from '../components/ProjectDetails/RelatedProjects'
import ProjectDetails from '../components/ProjectDetails'
import { useGetHomeDataQuery } from '../state/apiHome'
import i18next from 'i18next'

const Project = () => {
    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.projects)
        }
    }, [data, tableData, isBrandsLoading])
    return (
        <>
            <Header url={`مشاريعنا`} />
            <WrapperSection >
                <ProjectDetails />
                <WrapperSection title={data?.data?.otherProjectTitle}> 
                    <RelatedProjects data={tableData} />
                </WrapperSection>
            </WrapperSection>
        </>
    )
}

export default Project