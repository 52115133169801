import React, { useEffect, useState } from 'react'
import WrapperSection from '../glopal/WrapperSection'
import IntroductorySection from '../glopal/IntroductorySection'
import CardImg from '../glopal/CardImg'
// import img from './../../assets/WhoWeAre/about-home.png'
import i18next from 'i18next'
import { useGetHomeDataQuery } from '../../state/apiHome'

const Introduction = () => {
    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.about)
        }
    }, [data, tableData, isBrandsLoading])

    return (
        <>
            <WrapperSection>
                <IntroductorySection dir={'row'} title={tableData?.title} desc={tableData?.description} >
                    <CardImg img={tableData?.image} />
                </IntroductorySection>
            </WrapperSection>
        </>
    )
}
export default Introduction