import { CardMedia } from '@mui/material'
import React from 'react'
import Carousel from 'react-material-ui-carousel'

const SliderProject = ({images}) => {
    return (
        <>
            <Carousel
                animation='slide'
                IndicatorIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="10" viewBox="0 0 32 14">
                        <rect id="Rectangle_266" data-name="Rectangle 266" width="30" height="10" rx="2" />
                    </svg>
                }
                indicatorIconButtonProps={{
                    style: {
                        padding: '0',
                        fill: '#fff',
                        '&:hover': {
                            opacity: "0.6 !important"
                        },
                    }
                }}

                activeIndicatorIconButtonProps={{
                    style: {
                        fill: '#10446F',
                    }
                }}
                indicatorContainerProps={{
                    style: {
                        marginTop: '10px',
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: 0,
                        zIndex: 9
                    }
                }}
            >
                {images?.map((img, i) =>
                    <CardMedia
                        key={i}
                        sx={{
                            height: {
                                md: "15rem", xs: "250px",
                            },
                            width: "90%",
                            m: 'auto',
                            objectFit: "cover",
                        }}
                        component="img"
                        alt={img?.image}
                        src={img?.image} />)}
            </Carousel>
        </>
    )
}

export default SliderProject