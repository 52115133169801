import { Box, Button, InputLabel, Typography } from '@mui/material'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import './form.css'
import theme from '../../util/theme';
const Form = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service', 'template', form.current, 'user')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <div>
            <Box sx={{ borderRadius: '30px', }}>
                <Typography sx={{ color: theme.palette.secondary.main, my: 1 }} variant='h5' >For Contact</Typography>
                <Box component={'form'} ref={form} onSubmit={sendEmail} >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} >
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}>
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                Name <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <input
                                style={{ backgroundColor: '#FFF' }}
                                required
                                id="name"
                                variant="filled"
                                size="medium"
                                name='name'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                Phone Number<span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <input
                                required
                                style={{ backgroundColor: '#FFF' }}
                                id="email"
                                variant="filled"
                                size="medium"
                                name='email'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: '100%', my: 2 }}  >
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                Email <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <input
                                required
                                style={{ backgroundColor: '#FFF' }}
                                id="Phone"
                                variant="filled"
                                size="medium"
                                name='phone'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: { md: '100%', xs: '100%' }, }}  >
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                Message <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <textarea
                                required
                                style={{ backgroundColor: '#FFF', height: '175px', padding: '20px', resize: 'none' }}
                                id="outlined-multiline-flexible"
                                multiline={'true'}
                                rows={6}
                                variant="filled"
                                size="medium"
                                name='message'
                                className='input'
                            />
                        </Box>
                    </Box>
                    <Box mt={2} >
                        <Button variant="contained"
                            type="submit"
                            sx={{
                                backgroundColor: theme.palette.secondary.main, ':hover': {
                                    backgroundColor: theme.palette.secondary.main
                                },
                                color: "#FFF",
                                fontWeight: 'bold',
                                width: '100px'
                            }}>Send</Button>
                    </Box>
                </Box>
            </Box>
        </div >
    )
}
export default Form
