import React from 'react'
import './Header.css'
import { Box, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Header = ({ url }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className='header' >
            <div className="overlay"></div>
            <Box sx={{ position: 'relative', zIndex: '9' }} >
                <Typography variant='h4' sx={{ color: "#fff", fontWeight: 'bold', mb: 2 }} >
                    {url}
                </Typography>
                <Box sx={{ display: 'flex', color: '#FFF', justifyContent: 'center', gap: 1, alignItems:'center' }} >
                    <Typography onClick={() => navigate('/')} >{t('linksPages.home')}</Typography>
                    <EastIcon sx={{ color: '#FFCC00' }} />
                    <Typography color='primary.main'>{url}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default Header