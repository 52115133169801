import React from 'react'
import Introduction from '../components/AboutPage/Introduction'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
// import OurGuidelines from '../components/AboutPage/OurGuidelines'
import OurMission from '../components/AboutPage/OurMission'
import CoreValues from '../components/CoreValues'
// import OurVision from '../components/AboutPage/OurVision'

const AboutUs = () => {
    const {t} = useTranslation()
  return (
    <>
        <Header url={t("linksPages.Introduction")} />
        <Introduction />
        <CoreValues />
        {/* <OurGuidelines /> */}
        <OurMission />
        {/* <OurVision /> */}
    </>
  )
}

export default AboutUs