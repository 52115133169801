import React from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import { blogs } from '../constants'
import GlobalCard from '../components/glopal/GlobalCard'
import { Grid } from '@mui/material'
import GlobalList from '../components/glopal/GlobalList'
import WrapperSection from '../components/glopal/WrapperSection'

const OurBlogs = () => {
    const { t } = useTranslation()
    return (
        <>
            <Header
                url={t("linksPages.Blogs")}
            />
            <WrapperSection title={`From our blog`} >
                <GlobalList>
                    {
                        blogs?.map((blog, i) =>
                            <Grid item md={4} sm={6} xs={12} key={i} >
                                <GlobalCard
                                    Align={`left`}
                                    color={'secondary.supMain'}
                                    img={blog?.img}
                                    title={blog?.title}
                                    desc={blog?.desc}
                                />
                            </Grid>
                        )
                    }
                </GlobalList>
            </WrapperSection>
        </>
    )
}

export default OurBlogs