import { Box, Typography } from '@mui/material'
import React from 'react'
const Logo = ({name}) => {
  return (
    <>
        <Box sx={{ display:'flex', flexDirection:'column', gap:2, alignItems:'center', p:1 }} >
        <Typography color={'primary.text'} textAlign={'center'} >
          {name}
        </Typography>
        </Box>
    </>
  )
}

export default Logo