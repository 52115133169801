import React, { useEffect, useState } from 'react'
import WrapperSection from '../glopal/WrapperSection'
import GlobalList from '../glopal/GlobalList'
// import { values } from '../../constants'
import { Grid } from '@mui/material'
import CardValues from './CardValues'
import i18next from 'i18next'
import { useGetHomeDataQuery } from '../../state/apiHome'

const CoreValues = () => {
    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.goals)
        }
    }, [data, tableData, isBrandsLoading])
    return (
        <>
            <WrapperSection title={data?.data?.goalsTitle} >
                <GlobalList>
                    {
                        tableData?.map((value, i) =>
                            <Grid item md={4} sm={6} xs={12} key={i} >
                                <CardValues
                                    valueImg={value?.image}
                                    // title={lng === 'en' ? value?.name : value?.nameAr}
                                    description={value?.description}
                                />
                            </Grid>
                        )
                    }
                </GlobalList>
            </WrapperSection>
        </>
    )
}

export default CoreValues