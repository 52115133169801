import { Box, CardMedia } from '@mui/material'
import React from 'react'

const CardImg = ({ img }) => {
    return (
        <>
            <Box sx={{ width: { md: '48%', xs: '100%' } }} >
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    {/* الخط العلوي */}
                    <div style={{
                        position: 'absolute',
                        top: -10,
                        right: 0,
                        width: '50%',
                        borderBottom: '2px solid #10446F',
                        opacity: '13%'
                        // margin: '10px 0'
                    }} />

                    {/* الخط السفلي */}
                    <div style={{
                        position: 'absolute',
                        bottom: -10,
                        left: 0,
                        width: '50%',
                        borderTop: '2px solid #10446F',
                        opacity: '13%'
                        // margin: '10px 0'
                    }} />

                    {/* الصورة */}
                    <CardMedia
                        className='line'
                        component={'img'}
                        src={img}
                        sx={{ width: '100%', height: '100%', my: 2 }}
                    />
                </div>
            </Box>
        </>
    )
}

export default CardImg