import React, { useEffect, useState } from 'react'
import WrapperSection from '../glopal/WrapperSection'
import { useGetHomeDataQuery } from '../../state/apiHome';
import i18next from 'i18next';
import { Typography } from '@mui/material';

const SectionAboutHome = () => {
    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.bioSection)
        }
    }, [data, tableData, isBrandsLoading])
    return (
        <>
            <WrapperSection color={'#939494'} secColor={'#10446F'} title={tableData?.[0]?.value} desc={tableData?.[1]?.value} >
                <Typography color={'secondary.supMain'} textAlign={'center'} sx={{ my: 1.2 }} >{tableData?.[2]?.value}</Typography>
            </WrapperSection>
        </>
    )
}

export default SectionAboutHome