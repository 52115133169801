import React from 'react'
import SliderHome from '../components/SliderHome'
import SectionAboutHome from '../components/AboutHome/SectionAboutHome'
import Services from '../components/Services'
// import Definition from '../components/Definition'
import CoreValues from '../components/CoreValues'
import WhoWeAre from '../components/WhoWeAre'
import OurProject from '../components/OurProject'

const Home = () => {
  return (
    <>
      <SliderHome />
      <SectionAboutHome />
      <WhoWeAre />
      <Services />
      {/* <Definition /> */}
      <CoreValues />
      <OurProject />
    </>
  )
}

export default Home