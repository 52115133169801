import React, { useEffect, useState } from 'react'
import WrapperSection from '../glopal/WrapperSection'
// import FilterWork from '../glopal/FilterWork'
import GlobalList from '../glopal/GlobalList'
// import { projects1 } from '../../constants'
import { Box, CardMedia, Grid } from '@mui/material'
import './style.css'
import { useGetHomeDataQuery } from '../../state/apiHome'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'

const OurProject = () => {
    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.projects)
        }
    }, [data, tableData, isBrandsLoading])
    const navigate = useNavigate()
    return (
        <>
            <WrapperSection title={data?.data?.projectTitle} />
            <GlobalList>
                {
                    tableData?.map(project => <Grid item md={4} sm={6} xs={12} key={project?.id} >
                        <Box position={'relative'} className={'parent'} m={1} onClick={() => navigate(`/project/${project?.id}`)} >
                            <div className="overlay_project">
                                {project?.title}
                            </div>
                            <CardMedia
                                component={'img'}
                                src={project?.images?.[0]?.image}
                                sx={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '25rem',
                                }} />
                        </Box>
                    </Grid>
                    )
                }
            </GlobalList>
        </>
    )
}

export default OurProject