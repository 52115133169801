import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import theme from '../../util/theme'
import { useTheme } from '@emotion/react';

const CardValues = ({ bgBox = 'transparent', icon, bg = theme.palette.secondary.main, valueImg, title, description, width = 'auto', m = '16px', children }) => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (

        <Box
            sx={{
                textAlign: 'center',
                p: 2,
                borderRadius: '0 30px 0 30px',
                border: `1px solid ${theme.palette.secondary.main}`,
                height: '95%',
                my: 2,
                mx: 1,
                backgroundColor: bgBox,
                width: { md: width ,xs:'auto'},
                margin: m
            }} >
                {icon}
                {valueImg &&
            <CardMedia
            component={'img'}
            src={valueImg}
                sx={{
                    p: 1,
                    width: '50px',
                    height: '50px',
                    borderRadius: '8px',
                    objectFit: 'contain',
                    margin: 'auto',
                    background: bg
                }} />
            }
            <Typography variant={isMobile ? 'h6' :'h5'} sx={{
                color: 'secondary.main',
                fontWeight: 'bold',
                my: 2
            }}>
                {title}
            </Typography>
            <Typography sx={{
                color: '#1E6FA1',
                lineHeight: '1.8',
            }}>
                {description}
            </Typography>
            {children}
        </Box>

    )
}

export default CardValues