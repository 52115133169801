import React from 'react'
import './ScrollToTop.css'
import { Typography } from '@mui/material';
function ScrollTo() {
    React.useEffect(() => {
        let span = document.querySelector(".up");
        window.onscroll = function () {
            this.scrollY >= 1000 ? span.classList.add("show") : span.classList.remove("show");
        };
        span.onclick = function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
    }, []);
    const bottomToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <Typography onClick={bottomToTop} className="up">
            &#8593;
        </Typography>
    )
}

export default ScrollTo