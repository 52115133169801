import React from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import ContactSocial from '../components/ContactSocial'
import SocialContact from '../components/ContactSocial/SocialContact'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'

const ContactUs = () => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    const { t } = useTranslation()
    return (
        <>
            <Header
                url={(t("linksPages.ContactUs"))}
            />
            <ContactSocial />
            {/* {
                !isMobile && 
                <SocialContact />
            } */}
        </>
    )
}

export default ContactUs