import React from 'react'
import Header from '../components/glopal/Header'
import WrapperSection from '../components/glopal/WrapperSection'
import GlobalList from '../components/glopal/GlobalList'
import { Grid, useMediaQuery } from '@mui/material'
import BlogDetails from '../components/BlogDetails'
import BlogFilter from '../components/BlogFilter'
import imgBlog from './../assets/blog/blog.png'
import { useTheme } from '@emotion/react'
const Blog = () => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Header url={`our blog`} />
            <WrapperSection title={`our blog`} >
                <GlobalList>
                    <Grid item md={8} xs={12} >
                        <BlogDetails
                            img={imgBlog}
                            cat={isMobile ? `, August 9, 2022`:`design`}
                            createdBy={isMobile ? `Architecture`:`admin`}
                            dateBlog={` August 9, 2022`}
                            nameBlog={`Strategically Thinks and Acts`}
                            descBlog={`Lorem ipsum dolor sit amet consectetur Sed risus lectus ac semper id proin mi nulla tellus ellus fermentum morb
i a nisi ut amet leo fermentum eget Neque orci feugiat empus diam Aliquam in gravida pulvinar arcu porttitor
 viverra sed sem orci
 Lorem ipsum dolor sit amet consectetur Sed risus lectus ac semper id proin mi nulla tellus
i a nisi ut amet leo fermentum eget Neque orci feugiat empus diam Aliquam in gravida pulvinar arcu porttitor
 viverra sed sem orci`}
                        />
                    </Grid>
                    <Grid item md={4} xs={12} >
                        <BlogFilter />
                    </Grid>
                </GlobalList>
            </WrapperSection>
        </>
    )
}

export default Blog