import s1 from './../assets/services/1.png'
import s2 from './../assets/services/2.png'
import s3 from './../assets/services/3.png'
import s4 from './../assets/services/4.png'
import s5 from './../assets/services/5.png'
import s6 from './../assets/services/6.png'
import blog from './../assets/blog/top-view-messy-full-moving-boxes-room.png'

import one from './../assets/values/security.png'
import tow from './../assets/values/strategy.png'
import three from './../assets/values/passion.png'
import four from './../assets/values/integration (1).png'
import five from './../assets/values/friends.png'
import six from './../assets/values/teamwork.png'

import p1 from './../assets/projects/1.png'
import p2 from './../assets/projects/2.png'
import p3 from './../assets/projects/3.png'
import p4 from './../assets/projects/4.png'
import p5 from './../assets/projects/5.png'
import p6 from './../assets/projects/6.png'
import p7 from './../assets/projects/7.png'
import p8 from './../assets/projects/8.png'

import c1 from './../assets/contacts/Icon awesome-address-card.png'
import c2 from './../assets/contacts/Icon zocial-email.png'
import c3 from './../assets/contacts/Icon awesome-phone-square-alt.png'

import social1 from './../assets/Social/Icon awesome-facebook.png'
import social2 from './../assets/Social/Icon ionic-logo-instagram.png'
import social3 from './../assets/Social/Icon simple-whatsapp.png'

import contactFooter1 from './../assets/contactFooter/home.png'
import contactFooter2 from './../assets/contactFooter/mail.png'
import contactFooter3 from './../assets/contactFooter/phone.png'

export const Pages = [
        {
                path: `/`,
                name:`الرئيسية`,
                name_en:`Home`
        },
        {
                path: `about-us`,
                name:`من نحن`,
                name_en:`About`
        },
        {
                path: `services`,
                name:`خدماتنا`,
                name_en:`Our Services`
        },
        {
                path: `projects`,
                name:`المشاريع`,
                name_en:`Project History`
        },
        // {
        //         path: `blogs`,
        //         name:`المقالات`,
        //         name_en:`Our Blog`
        // },
        {
                path: `contacts-us`,
                name:`اتصل بنا`,
                name_en:`Contact us`
        },
]

export const services = [
        {
                img: s1,
                title:`محطات توليد الطاقة وتمديد الكهرباء`,
                desc:`ELECTRICAL website`
        },
        {
                img: s2,
                title:`صيانة وتنفيذ الطرق والجسور`,
                desc:`ELECTRICAL website`
        },
        {
                img: s3,
                title:`الانشاءات العامة للمباني السكني`,
                desc:`ELECTRICAL website`
        },
        {
                img: s6,
                title:`انشاء شبكات تصريف السيول`,
                desc:`ELECTRICAL website`
        },
        {
                img: s5,
                title:`تأجير المعدات الثقيلة `,
                desc:`ELECTRICAL website`
        },
        {
                img: s4,
                title:`تشغيل أعمال المياه والصرف الصحي`,
                desc:`ELECTRICAL website`
        }
]

export const values = [
        {
                id: 1,
                imageUrl: one,
                name: `Safety First`,
                supName: `We make the well-being of our people our top
 priority everywhere we work by nurturing
 a culture that lives and breathes safety`,
                nameAr: `السلامة أولا`,
                supNameAr: `أن تصبح الخيار الأول للجهات والمصالح
 والشركات والافراد وأن تظل محل ثقة 
واعتزاز لكافة عملائنا`,
        },
        {
                id: 2,
                imageUrl: tow,
                name: `Strategically Thinks and Acts`,
                supName: `We have built an agile, responsive culture 
that focuses on our client’s needs and
 provides customized solutions with certainty 
of completion`,
                nameAr: `التفكير والعمل الاستراتيجي`,
                supNameAr: `إقامة علاقة مبنية على الثقة المتبادلة
 والنزاهة والقدرة المالية `,
        },
        {
                id: 3,
                imageUrl: three,
                name: `Passion`,
                supName: `Our passion is to bring new, innovative and
 sustainable ideas to our clients.`,
                nameAr: `شغف`,
                supNameAr: `خدمات الجودة الشاملة رفع وتطوي
ر مستويات البناء والتشييد`,
        },
        {
                id: 4,
                imageUrl: four,
                name: `Integrity, Honesty and Trust`,
                supName: `We are committed to upholding high ethical 
standards in all we do. We lead by example
 act responsibly to make a positive contribution
 to our communities.`,
                nameAr: `النزاهة والصدق والثقة`,
                supNameAr: `تحقيق أقصى درجات ومعايير
 الجودة والسلامة `,
        },
        {
                id: 5,
                imageUrl: five,
                name: `Right People, Right Job`,
                supName: `We create exciting opportunities for our employees, 
clients and communities by growing`,
                nameAr: `الشخص الصحيح للعمل الصحيح `,
                supNameAr: `جذب المزيد من العملاء في المستقبل`,
        },
        {
                id: 6,
                imageUrl: six,
                name: `Teamwork`,
                supName: `We deliver a consistent, quality service, sharing
 knowledge across the nation and capturing valuable 
perspective`,
                nameAr: `عمل الفريق `,
                supNameAr: ` الانتشــــار والتوســـع`,
        },
];

export const blogs = [
        {
                img: blog,
                title: `Strategically Thinks and Acts`,
                desc: `Strategically Thinks and Acts website`
        },
        {
                img: blog,
                title: `Strategically Thinks and Acts`,
                desc: `Strategically Thinks and Acts website`
        },
        {
                img: blog,
                title: `Strategically Thinks and Acts`,
                desc: `Strategically Thinks and Acts website`
        },
        {
                img: blog,
                title: `Strategically Thinks and Acts`,
                desc: `Strategically Thinks and Acts website`
        },
        {
                img: blog,
                title: `Strategically Thinks and Acts`,
                desc: `Strategically Thinks and Acts website`
        },
        {
                img: blog,
                title: `Strategically Thinks and Acts`,
                desc: `Strategically Thinks and Acts website`
        }
]

export const projects1 = [
        {
                id: 1,
                imageUrl: p1,
                name:'nameProject'
        },
        {
                id: 2,
                imageUrl: p2,
                name:'nameProject'
        },
        {
                id: 3,
                imageUrl: p3,
                name:'nameProject'
        },
        {
                id: 4,
                imageUrl: p4,
                name:'nameProject'
        },
        {
                id: 5,
                imageUrl: p5,
                name:'nameProject'
        },
        {
                id: 6,
                imageUrl: p6,
                name:'nameProject'
        },
        {
                id: 7,
                imageUrl: p7,
                name:'nameProject'
        },
        {
                id: 8,
                imageUrl: p8,
                name:'nameProject'
        },

];

export const contacts = [
//         {
//                 id: 1,
//                 imageUrl: c1,
//                 name: `X`,
//                 supName: `Office 48, near Dubai police General 
// HQ,hor Al hanz East,, Dubai-UAE`,
//                 nameAr: `X`,
//                 supNameAr: `Office 48, near Dubai police General 
// HQ,hor Al hanz East,, Dubai-UAE`,
//         },
        {
                id: 2,
                imageUrl: c2,
                name: `Email`,
                supName: `Info@sac-company.com`,
                nameAr: `Email`,
                supNameAr: `Info@sac-company.com`,
        },
        {
                id: 3,
                imageUrl: c3,
                name: `Phone`,
                supName: `+966544448386`,
                nameAr: `Phone`,
                supNameAr: `+966544448386`,
                desc: "+971 55 638 6323"
        },
];

export const socials = [
        {
                id: 1,
                src:social1
        },
        {
                id: 1,
                src:social2
        },
        {
                id: 1,
                src:social3
        },
]
export const contact = [
        {
                id: 1,
                src: contactFooter3,
                text:'+49 123 456 789'
        },
        {
                id: 1,
                src: contactFooter2,
                text:'support@robert.com'
        },
        {
                id: 1,
                src: contactFooter1,
                text:' City Street, Barcelona (Spain)'
        },
]