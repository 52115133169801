import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SliderProject from './SliderProject'
import Details from './Details'
import i18next from 'i18next'
import { useGetProjectIdDataQuery } from '../../state/apiProjects'
import { useParams } from 'react-router-dom'

const ProjectDetails = () => {
  let lng = i18next.language
  let { id } = useParams()
  const { data, isBrandsLoading } = useGetProjectIdDataQuery({id, lng});

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data && !isBrandsLoading) {
      setTableData(data?.data)
    }
  }, [data, tableData, isBrandsLoading])

  return (
    <>
        <Grid container alignItems={'center'} rowGap={1} >
            <Grid item md={6} xs={12}>
          <SliderProject images={tableData?.images} />
            </Grid>
            <Grid item md={6} xs={12}>
                <Details data={tableData} />
            </Grid>
        </Grid>
    </>
  )
}

export default ProjectDetails