import React, { useEffect, useState } from 'react'
import WrapperSection from '../glopal/WrapperSection'
import IntroductorySection from '../glopal/IntroductorySection'
import CardImg from '../glopal/CardImg'
// import img from './../../assets/WhoWeAre/about-home.png'
import Btn from '../glopal/Btn'
import i18next from 'i18next'
import { useGetHomeDataQuery } from '../../state/apiHome'
import { useTranslation } from 'react-i18next'

const WhoWeAre = () => {
    let lng = i18next.language
    const { data, isBrandsLoading } = useGetHomeDataQuery(lng);
const {t} = useTranslation()
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isBrandsLoading) {
            setTableData(data?.data?.about)
        }
    }, [data, tableData, isBrandsLoading])

    return (
        <>
            <WrapperSection>
                <IntroductorySection dir={'row'} title={tableData?.title} desc={tableData?.description} Bt={<Btn path={tableData?.btn_text} text={t("btn")} />} >
                    <CardImg img={tableData?.image} />
                </IntroductorySection>
            </WrapperSection>
        </>
    )
}

export default WhoWeAre  