import React from 'react'
import { Box, Typography } from '@mui/material'
const CustomerService = ({phone}) => {
  // let lng = i18next.language
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', p: 1 }} >
        <Typography color={'primary.text'} textAlign={'center'} >
          {phone}
        </Typography>
      </Box>
    </>
  )
}

export default CustomerService