import React from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import OurProject from '../components/OurProject'

const OueWork = () => {
    const { t } = useTranslation()
  return (
    <>
          <Header url={t("linksPages.ProjectHistory")}/>
          <OurProject />
    </>
  )
}

export default OueWork