import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


const GlobalCard = ({ img, Align, title, color, desc }) => {

    return (
        <Card sx={{ maxWidth: 345, my: 1, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.20)", }}>
            <CardMedia
                sx={{ height: '20rem' }}
                image={img}
                title="green iguana"
            />
            <CardContent sx={{ textAlign: Align }} >
                <Typography gutterBottom variant="h6" component="div" color={'secondary.main'} sx={{ textTransform:'uppercase' }} >
                    {title}
                </Typography>
                <Typography variant="body2" color={color ? color : 'secondary.main'}>
                    {desc}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default GlobalCard