import { Box, Typography } from '@mui/material'
import React from 'react'
// import { contact } from '../../../constants'

const Contact = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', p: 1 }} >
                <Typography color={'primary.text'} textAlign={'center'} >
                    Copyright © 2024Revamp. All rights reserved.
                </Typography>
            </Box>
        </>
    )
}

export default Contact