import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const api = createApi({
  baseQuery: fetchBaseQuery({
    mode: 'cors',
    baseUrl: 'https://backend.sac-company.com/api/v1',
    prepareHeaders: (headers, { getState }) => {
      headers.set("Accept", "application/json") 
      headers.set("Cache-Control", "no-cache");
      return headers
    } 
  }),
  reducerPath: "apiHome",
  tagTypes: ["basicData", 'home', 'slider'],
  endpoints: (build) => ({
    getHomeData: build.query({
      query: (lng) => `/home?local=${lng}`,
      providesTags: ["home"],
    }),
    getBasicData: build.query({
      query: (lng) => `/basicData?local=${lng}`,
      providesTags: ["basicData"],
    }),
    getHomeSlider: build.query({
      query: (lng) => `/slider?local=${lng}`,
      providesTags: ["slider"],
    }),
  }),
});


export const {
  useGetHomeDataQuery,
  useGetBasicDataQuery,
  useGetHomeSliderQuery,
} = api;